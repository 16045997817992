export let tFilters = {
  filters: {
    currency(val) {
      return val.toFixed(2)
    },
    commaThousand(val) {
      let parts = val.toString().split(".")
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return `$ ${parts.join(".")}`
    },
    spaceCreditCardNumber(val) {
      return val.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()
    },
    twoDecimals(val) {
      let parts = val.toString().split(".")
      if (parts.length >= 2) {
        parts[1] = parts[1].substring(0, 2)
      }
      return `${parts.join(".")}`
    }
  }
}
