<template>
  <v-container class="my-4 py-6">
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
    >
      Construfácil está comprometido con la seguridad de tus datos, por lo cual tus datos personales son encriptados en nuestra base de datos y confiamos en Stripe para manejar nuestras transacciones.
    </v-alert>
    <h2>
      Mis Pagos
      <v-btn
        class="mx-4 py-4"
        fab
        small
        color="primary"
        @click="showForm = !showForm"
      >
        <v-icon dark>
          {{ this.showForm ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
      </v-btn>
    </h2>
    <p v-if="showErrorMsg">Checar que funcione esto</p>
    <v-row
      v-if="showForm"
      justify="center"
      class="my-6"
    >
      <v-col
        align-self="center"
        cols="12"
        md="6"
      >
        <v-card
          class="py-2 px-4 mx-auto credit-card"
          height="150"
          max-width="300"
          max-height="150"
        >
          <p class="my-4 text-secondary text-center">
            {{ userCard.numero_tarjeta | spaceCreditCardNumber }}
          </p>
          <p class="my-2 mx-2 text-left">
            {{ userCard.nombre_tarjeta }}
          </p>
          <p class="my-2 mx-2 text-right">
            {{ userCard.mes_expiracion }} / {{ userCard.anio_expiracion}}
          </p>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
        align-self="center"
      >
        <v-card 
          class="px-4 mx-auto"
          max-width="600"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="mx-auto"
              >
                <v-text-field
                  v-model="userCard.numero_tarjeta"
                  label="Número de la Tarjeta"
                  :rules="[rules.cardLength]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="10"
                class="mx-auto"
              >
                <v-text-field
                  v-model="userCard.nombre_tarjeta"
                  label="Nombre de la Tarjeta"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="userCard.mes_expiracion"
                  label="Mes"
                  :rules="[rules.cardMonth]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col 
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="userCard.anio_expiracion"
                  label="Año"
                  :rules="[rules.cardYear]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="userCard.cvc"
                  label="CVC"
                  :rules="[rules.cardCvc]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-checkbox
                  class="my-0 py-0"
                  v-model="userCard.es_predeterminado"
                  label="Predeterminado"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              :disabled="!valid"
              color="primary"
              @click="sendCard"
            >
              Guardar
            </v-btn>
            <v-btn
              color="primary"
              @click="cleanForm"
            >
              limpiar campos 
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      class="my-4"
      justify="center"
    >
      <p v-if="!userCards.length">Por favor agrega un método de pago.</p>
      <v-card
        v-for="card in userCards"
        :key="card.id"
        class="mx-4 my-6 px-4 credit-card"
        width="300"
        height="150"
        max-width="300"
        max-height="150"
        :style="selectedUserCard === card.idCard ?
                'border: 2px solid #FF4C00;' :
                ''"
      >
        <div class="my-1 d-flex"> 
          <div style=" display: inline;" v-show="card.predeterminado">Predeterminada</div>
          <v-btn
            class="ml-auto px-0"
            height="auto"
            min-width="auto"
            @click="removeCard(card.idCard)"
            text
          >
            <v-icon
              color="red"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <p class="my-4 text-secondary text-center">
          ************{{ card.last4 | spaceCreditCardNumber }}
        </p>
        <p class="my-2 mx-2 text-left">
          {{ card.nombre_tarjeta }}
        </p>
        <p class="my-2 mx-2 text-right">
          {{ addPadMonth(card.exp_month) }} / {{ card.exp_year }}
        </p>
        <v-card-actions>
          <v-btn
            class="mt-2"
            v-if="selectedCard"
            text
            color="primary"
            @click="emitSelectedCard(card)"
          >
            Seleccionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { ApiAuth } from '@/api_utils/axios-base'
import { mapState } from 'vuex'
import { validationMixin } from '@/mixins/formsValidations.js'
import { tFilters } from '@/mixins/filters.js'

export default {
  metaInfo: {
    title: 'Mis pagos'
  },
  mixins: [validationMixin, tFilters],
  props: {
    selectedCard: Boolean,
  },
  data() {
    return {
      userCard: {
        nombre_tarjeta: "",
        numero_tarjeta: "",
        mes_expiracion: "",
        anio_expiracion: "",
        cvc: "",
        es_predeterminado: false
      },
      showForm: false,
      valid: true,
      userCards: [],
      showErrorMsg: false,
      erroMsg: "",
      selectedUserCard: null,
    }
  },
  created() {
    this.getCards()
  },
  computed: {
    ...mapState('usuario', ['userData']),
  },
  methods: {
    async sendCard() {
      if(!this.$refs.form.validate()) {
        // console.log("validation error")
        return
      } else {
        try {
          this.$store.commit('setLoading', true)
          this.userCard.id_usuario = this.userData.id
          if(this.userCard.es_predeterminado) {
            this.setPredeterminadoFalse()
          }
          // console.log("tarjeta que se envía", this.userCard)
          await ApiAuth.post('/personas/crear-tarjeta', this.userCard)
            .then(response => {
              this.getCards()
              this.showForm = false
              setTimeout(() => { this.$store.commit('setLoading', false)}, 500)
            })
          this.cleanForm()

        } catch(error) {
          console.error("error creating new card", error)
          // probar que errores puede arrojar stripe
          if(error.response) {
            this.$store.commit('setLoading', false)
            // console.log("err", error)
            this.showErrorMsg = true
          }
        }
      }
    },

    async getCards() {
      try {
        let cardResponse = await ApiAuth.get(`/personas/api/card/?id_usuario=${this.userData.id}`)
        this.userCards = await cardResponse.data
        let uCard = this.userCards.find(card => card.predeterminado === true) || ""
        this.emitPredeterminadaTarjeta(uCard)

      } catch(error) {
        console.error("error getting cards", error)
      }
    },

    async removeCard(cardId) {
      try {
        this.$store.commit('setLoading', true)
        let deleteCardResponse = await ApiAuth.post('/personas/eliminar-tarjeta', 
          {
            id_usuario: this.userData.id,
            id_card: cardId
          }
        )
        this.getCards()
        setTimeout(() => { this.$store.commit('setLoading', false)}, 500)

      } catch(error) {
        console.error("error deleting card", error)
        this.$store.commit('setLoading', false) 
      }
    },
 
    cleanForm() {
      Object.keys(this.userCard).forEach(k => this.userCard[k]= "")
    },

    addPadMonth(month) {
      return month.toString().padStart(2, '0')
    },

    emitSelectedCard(card) {
      this.$emit('tarjetaSeleccionada', card)
      this.selectedUserCard = card.idCard
    },

    emitPredeterminadaTarjeta(card) {
      this.$emit('tarjetaPredeterminada', card)
      this.selectedUserCard = card.idCard
    },

    setPredeterminadoFalse() {
      let uCards = this.userCards.find(card => card.predeterminado === true)
      if(uCards) {
        uCards.predeterminado = false
        //Borrar estos dos campos, los pongo porque el endpoint los da en blanco
        uCards.exp_month=4
        uCards.exp_year=2020
        ApiAuth.put(`/personas/api/card/${uCards.id}/`, uCards)
          .then(resp => {
            console.log("modificado!", resp)
          })
      } else {
        return
      }
    }
  }
}
</script>

<style scoped>
.credit-card{
  background-color: #DADADA;
  border-radius: 10px;
}
.line-card{
  background-color: gray;
  width: 100%;
  height: 10px;
}
</style>
